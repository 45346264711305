import { useContext } from "react";
import { TableContext, SettingsContext, CartContext, UserContext, CategoriesContext } from "../App";
import Label from "./Label";
import "../css/Footer.css";
import currencyFormat from "./functions/functions";

function Footer() {
	const {
		settings,
		setCartVisible,
		setUserPopupVisible,
		setCourse,
		setCategory,
		setPromoPopup,
		setOrdersVisible,
		monoOrder,
	} = useContext(SettingsContext);
	const { table } = useContext(TableContext);
	const { cart } = useContext(CartContext);
	const { user } = useContext(UserContext);
	const { categories } = useContext(CategoriesContext);

	const goToHome = () => {
		if (settings.selfordering.hide_home == 1) {
			if (categories.length > 0) {
				setCourse(categories[0]);
				console.log(categories[0].categories.length);
				if (categories[0].categories.length > 0) {
					console.log(categories[0].categories[0]);
					if (categories[0].categories[0]) setCategory(categories[0].categories[0]);
				}
			}
		} else {
			if (categories && categories.length > 1) setCourse(false);
			setCategory(false);
		}
	};

	return (
		<div className="footer-container">
			{cart && cart.total_items > 0 && (
				<div className="footer-cart">
					<div className="info">
						{settings.selfordering.show_promo == 1 && (
							<button className="promo-button" onClick={() => setPromoPopup(true)}>
								<i className="fa fa-ticket" />
								<Label number={110} />
							</button>
						)}
						<div className="icon">
							<i className="fa fa-shopping-cart" />
							<div className="counter">{cart.total_items}</div>
						</div>
						<span>{currencyFormat(cart.total)}</span>
					</div>
					<button className="cart-button" onClick={() => setCartVisible(true)}>
						<Label number={19} />
					</button>
				</div>
			)}
			<div className="buttons">
				<button onClick={goToHome}>
					<div className="icon">
						<i className="fa fa-home" />
					</div>
					<Label number={108} />
				</button>
				{!monoOrder && (
					<button onClick={() => setOrdersVisible(true)}>
						<div className="icon">
							<i className="fa fa-list" />
						</div>
						<Label number={76} />{" "}
						{settings.selfordering.table_label == 1 && (
							<>
								<Label number={22} />{" "}
							</>
						)}
						{table.name}
					</button>
				)}
				<button onClick={() => setCartVisible(true)}>
					<div className="icon">
						<i className="fa fa-shopping-cart" />
						{cart && <div className="counter">{cart.total_items}</div>}
					</div>
					<Label number={19} />
				</button>
				{settings.selfordering.enable_login == 1 && (
					<button onClick={() => setUserPopupVisible(true)}>
						<div className="icon">
							<i className="fa fa-user" />
						</div>
						{user ? user.name + " " + user.surname : <Label number={23} />}
					</button>
				)}
			</div>
			<div className="subfooter">
				{settings.selfordering.hide_list != 1 && cart.list_id && cart.list.name + " - "}
				{settings.selfordering.footer_text}
			</div>
		</div>
	);
}

export default Footer;
