import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../App";
import api from "./api/api";

export default function KioskPaymentPopUp({ order }) {
	const { setKioskPaymentPopUpVisible, setPin } = useContext(SettingsContext);
	const [status, setStatus] = useState(false);
	let timer;

	useEffect(() => {
		getOrderStatus();
		return () => {
			console.log("clear timer");
			clearTimeout(timer);
		};
	}, []);

	useEffect(() => {
		if (status == "confirmed") {
			setTimeout(() => setKioskPaymentPopUpVisible(false), 10000);
			setPin(false);
		}
	}, [status]);

	const getOrderStatus = async (shop_id, name) => {
		console.log("getOrderStatus");
		const response = await api.post("/orders/get/" + order.id + "/", {
			fields: ["status"],
		});
		if (response.success === 0) alert(response.error);
		else {
			setStatus(response.data.status);
			timer = setTimeout(getOrderStatus, 5000);
		}
	};

	return (
		<div className="kiosk-payment-popup">

			{status == "awaiting_payment" && (
				<div>
					<div><i className="fas fa-solid fa-circle-notch fa-spin"></i></div>
					<div className="info-text">
						Segui le istruzioni sul lettore di carte di credito per completare il
						pagamento
					</div>
					<button onClick={() => setKioskPaymentPopUpVisible(false)}>Annulla</button>
				</div>

			)}
			{status == "confirmed" && (
				<div>
					<div><i className="fas fa-solid fa-check"></i></div>
					<div className="info-text">Pagamento completato</div>
					<button onClick={() => setKioskPaymentPopUpVisible(false)}>Nuovo Ordine</button>
				</div>
			)}
		</div>
	);
}
