import { useContext } from "react";
import { SettingsContext } from "../App";
import "../css/LangSelector.css";
import Label from "./Label";
import it from "../images/flags/it.png";
import en from "../images/flags/en.png";
import fr from "../images/flags/fr.png";
import zh from "../images/flags/zh.png";

function LangSelector() {
	const { settings, setLang } = useContext(SettingsContext);

	const handleClick = (lang) => {
		setLang(lang);
		localStorage.setItem("lang", lang);
	};

	const findImage = (lang) => {
		if (lang == "it") return it;
		if (lang == "en") return en;
		if (lang == "fr") return fr;
		if (lang == "zh") return zh;
		else return null;
	};

	return (
		<>
			<hr />
			<div className="lang-selector">
				<div className="label">
					<Label number={18} />:
				</div>
				<div className="lang-buttons">
					{settings.global.it == 1 && (
						<a href="#" onClick={() => handleClick("it")}>
							<img src={findImage("it")} title="it" />
						</a>
					)}
					{settings.global.en == 1 && (
						<a href="#" onClick={() => handleClick("en")}>
							<img src={findImage("en")} title="en" />
						</a>
					)}
					{settings.global.fr == 1 && (
						<a href="#" onClick={() => handleClick("fr")}>
							<img src={findImage("fr")} title="fr" />
						</a>
					)}
					{settings.global.zh == 1 && (
						<a href="#" onClick={() => handleClick("zh")}>
							<img src={findImage("zh")} title="zh" />
						</a>
					)}
				</div>
			</div>
		</>
	);
}

export default LangSelector;
