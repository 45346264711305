import { Fragment, useContext, useEffect, useState } from "react";
import { SettingsContext } from "../App";
import currencyFormat from "./functions/functions";
import "../css/Variants.css";
import CloseButton from "./CloseButton";
import Allergens from "./Allergens";
import Label from "./Label";
import Tags from "./Tags";
import AddToCartButton from "./AddToCartButton";

function ProductSheet({ product }) {
	const { settings, setProductSheet, lang } = useContext(SettingsContext);

	return (
		<div id="product-sheet" className="popup">
			<div className="content">
				<div className="popup-header">
					<CloseButton onClick={() => setProductSheet(false)} />
					<h3 className="mb-0">{product.name}</h3>
					{currencyFormat(product.price, false)}
				</div>
				<div className="popup-scroll">
					<div className="popup-content">
						<Tags product={product} className="mb-2" />
						<div className="info row">
							{product.img && (
								<div className="img col-sm-4 mb-3">
									<img src={product.img} />
								</div>
							)}
							{product.description && (
								<div className="description col mb-3">
									{product.description && (
										<div
											dangerouslySetInnerHTML={{
												__html: product.description,
											}}
										/>
									)}
								</div>
							)}
						</div>
						<div className="row">
							{product.ingredients.length > 0 && (
								<div className="col-sm mb-3">
									<div
										className="subtitle"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={40} />
									</div>
									<div className="ingredients">
										{product.ingredients.map((ingredient, i) => (
											<Fragment key={i}>
												{ingredient.name}
												{i < product.ingredients.length - 1 && ", "}
											</Fragment>
										))}
									</div>
								</div>
							)}
							{product.allergens.length > 0 && (
								<div className="col-sm mb-3">
									<div
										className="subtitle"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={41} />
									</div>
									<div className="allergens">
										<Allergens
											allergens={product.allergens}
											color="#000"
											backgroundColor="#ccc"
											lang={lang}
										/>
									</div>
								</div>
							)}
						</div>
						<div className="buttons">
							{product.disabled != 1 && (
								<AddToCartButton product={product} label={true} />
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProductSheet;
