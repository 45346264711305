const currencyFormat = (number, hideCentsIfZero = false) => {
	const [currency, cents] = Number(number).toFixed(2).toString().split(".");
	if (hideCentsIfZero && cents == 0) {
		return `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`;
	} else {
		return `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${cents} €`;
	}
};

export function replaceAll(str, find, replace) {
	return str.replace(new RegExp(find, "g"), replace);
}

export function cutString(string, maxLength) {
	if (string.length > maxLength) {
		var trimmedString = string.substr(0, maxLength);
		trimmedString = trimmedString.substr(
			0,
			Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
		);
		return trimmedString + "...";
	} else return string;
}

export const getStatusLabel = (status) => {
	if (status == "confirmed") status = 77;
	if (status == "filled") status = 78;
	if (status == "archived") status = 79;
	if (status == "cancel_request") status = 80;
	if (status == "ready") status = 81;
	if (status == "preparation") status = 82;
	if (status == "unconfirmed") status = 83;
	return status;
};

export const getStatusColor = (status) => {
	if (status == "unconfirmed") status = "#FCE83A";
	if (status == "confirmed") status = "#FCE83A";
	if (status == "preparation") status = "#FFB302";
	if (status == "ready") status = "#56F000";
	if (status == "filled") status = "#2DCCFF";
	if (status == "archived") status = "#A4ABB6";
	if (status == "cancel_request") status = "#A4ABB6";
	return status;
};

export const getTranslatedString = (obj, name, lang) => {
	if (obj["translations"] && obj["translations"][lang][name])
		return obj["translations"][lang][name];
	return obj[name];
};

export const textLabel = (labels, number, lang) => {
	if (labels) {
		let l = labels.filter((l) => {
			return l.id === number;
		});
		if (l.length > 0) return l[0][lang];
	}
};

export default currencyFormat;
