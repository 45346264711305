import { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/it";
import Api from "./api/api";
import { AlertContext, TableContext, SettingsContext, CartContext } from "../App";
import currencyFormat, { getStatusColor, getStatusLabel } from "./functions/functions";
import "../css/Orders.css";
import CloseButton from "./CloseButton";
import Label from "./Label";
import moment from "moment/moment";

function Orders({ setOrdersVisible }) {
	const { settings, lang, initTablePayment, userID, setLoading, singleOrderMode } =
		useContext(SettingsContext);
	const { table } = useContext(TableContext);
	const { cart } = useContext(CartContext);
	const { setAlertData } = useContext(AlertContext);
	const [orders, setOrders] = useState(false);

	let orders_timeout;

	useEffect(() => {
		load_orders();
		return () => {
			console.log("clear_orders_timeout");
			clearTimeout(orders_timeout);
		};
	}, []);

	const load_orders = async () => {
		console.log("load_orders");
		let request = {
			lang: lang,
			src: [],
		};
		if (singleOrderMode) {
			request.src.push({
				name: "temp_customer_id",
				value: userID,
				compare: "equal",
			});
			request.src.push({
				name: "delivery_date",
				value: moment().format("YYYY-MM-DD"),
				compare: "date",
			});
		} else {
			request.src.push({
				name: "self_cart_id",
				value: cart.cart_id,
				compare: "equal",
			});
		}
		const response = await Api.post("/orders/list/", request);
		//console.log(response);
		setOrders(response.rows);
		orders_timeout = setTimeout(load_orders, 30000);
	};

	const loadBillUrl = async (order) => {
		if (order.bill_url) window.open(order.bill_url, "_blank");
		else {
			setLoading(true);
			const response = await Api.post("/orders/get_bill_url/" + order.id + "/");
			setLoading(false);
			if (response.success == 0) alert(response.error);
			else window.open(response.bill_url, "_blank");
		}
	};

	return (
		<>
			<div className="popup orders">
				<div className="content">
					<div className="orders-header">
						<CloseButton onClick={() => setOrdersVisible(false)} />
						<h3 className="mb-0">
							{settings.selfordering.table_label == 1 && (
								<>
									<Label number={22} />{" "}
								</>
							)}
							{table.name}
						</h3>
					</div>
					<div className="orders-scroll">
						{orders &&
							orders.map((order, n) => (
								<div key={n} className="orders-content">
									<div className="orders-subtitle">
										<div>
											<Moment format={"LT"} locale="it">
												{order.delivery_date}
											</Moment>{" "}
											- N°{order.number} (ID:{order.id})
										</div>
										<div>
											{order.scloby_sell_id && (
												<button onClick={() => loadBillUrl(order)}>
													Scontrino
												</button>
											)}
										</div>
									</div>
									<div className="orders-products">
										{order.products.map((product, i) => (
											<div key={i}>
												<div>
													{product.qty}x {product.name}
												</div>
												<StatusTag status={product.status} />
											</div>
										))}
									</div>
								</div>
							))}
					</div>
					{!singleOrderMode && (
						<div className="orders-footer">
							{settings.selfordering.hide_total == 0 && (
								<div className="orders-total">
									<Label number={25} />:{" "}
									<span>{currencyFormat(cart.table_total)}</span>
								</div>
							)}
							<div className="buttons">
								<button onClick={() => setOrdersVisible(false)}>
									<Label number={6} />
								</button>
								{settings.selfordering.disable_table_checkout != 1 && (
									<button
										className="button-primary"
										onClick={() => initTablePayment()}
									>
										<Label number={24} />
									</button>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

function StatusTag({ status }) {
	return (
		<div className="status-tab" style={{ backgroundColor: getStatusColor(status) }}>
			<Label number={getStatusLabel(status)} />
		</div>
	);
}

export default Orders;
