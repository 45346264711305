import { useContext } from "react";
import { CartContext, SettingsContext } from "../../App";
import CartQtySelector from "../CartQtySelector";
import currencyFormat, { replaceAll } from "../functions/functions";
import NotesIcon from "../../icons/NotesIcon";

function Product({ index, product, timerActive, updateQty }) {
	const { settings, setProductNotesPopup } = useContext(SettingsContext);
	const { cart } = useContext(CartContext);

	return (
		<div className="cart-row">
			<div className="name">
				{cart.allyoucaneat == 1 && settings.selfordering?.disable_timer_for_priced == 1 && (
					<>
						{product.price == 0 && timerActive ? (
							<i className="fas fa-circle text-danger me-2"></i>
						) : (
							<i className="fas fa-circle text-success me-2"></i>
						)}
					</>
				)}
				{product.name}
				{product.discounted == 1 ? (
					<div className="small">
						<s>{currencyFormat(product.base_price)}</s> {currencyFormat(product.price)}
					</div>
				) : (
					<div className="small">{currencyFormat(product.price)}</div>
				)}
				<div className="small">
					{product.variants.map((variant, i) => (
						<span key={i}>
							{variant.qty}x {variant.name}
							{i < product.variants.length - 1 && ", "}
						</span>
					))}
				</div>
				{product.products?.map((subproduct, i) => (
					<div
						key={subproduct.variant_category_id + "-" + subproduct.product_id}
						className="small"
					>
						<div>
							{subproduct.qty}x {subproduct.name}
						</div>
					</div>
				))}
				<div className="small">
					{product.notes && product.notes.replace(/<br\s*[\/]?>/gi, "\n")}
				</div>
			</div>
			<div className="prices">
				<div className="qty">
					{product.removable == 1 ? (
						<CartQtySelector
							value={product.qty}
							onChange={(qty) =>
								updateQty(
									product.product_id,
									product.item_id,
									qty,
									product.price,
									false
								)
							}
						/>
					) : (
						product.qty
					)}
					{settings.selfordering?.enable_products_notes == 1 && (
						<button
							className="notes-button"
							onClick={() => setProductNotesPopup(product.item_id)}
						>
							<NotesIcon />
						</button>
					)}
				</div>
				<div className="price">
					{currencyFormat(parseInt(product.qty) * parseFloat(product.price))}
				</div>
			</div>
		</div>
	);
}

export default Product;
