import { useContext } from "react";
import { AlertContext, SettingsContext, UserContext } from "../../App";
import Api from "../api/api";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/it";
import Label from "../Label";
import areaCodes from "../../data/areaCodes.json";

function Profile({ setUserPopupVisible }) {
	const { settings, legal, setLegalPupop, setLoading } = useContext(SettingsContext);
	const { user, setUser } = useContext(UserContext);
	const { setAlertData } = useContext(AlertContext);

	const save = async (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		const request = Object.fromEntries(data.entries());
		setLoading(true);
		const response = await Api.post("/customers/edit/" + user.id + "/", request);
		setLoading(false);
		if (response.success == 0) {
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
			return false;
		}
		setUser(response.data);
	};

	const logout = async () => {
		setAlertData({
			title: <Label number={47} />,
			showCancelButton: true,
			callback: () => {
				setUser(false);
				localStorage.removeItem("customer_id");
				setUserPopupVisible(false);
			},
		});
	};

	const deleteAccount = async () => {
		setAlertData({
			title: <Label number={47} />,
			showCancelButton: true,
			callback: () => {
				setAlertData({
					title: <Label number={102} />,
					message:
						"Questa operazione non è reversivile, desideri procedere con l'eliminazione?",
					showCancelButton: true,
					callback: () => {
						apiDelete();
					},
				});
			},
		});
	};

	const apiDelete = async () => {
		setLoading(true);
		const response = await Api.post("/customers/delete/" + user.id + "/");
		setLoading(false);
		if (response.success == 0) {
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
			return false;
		} else {
			setAlertData({
				message: <Label number={105} />,
				confirmMessage: <Label number={31} />,
			});
		}
		setUser(false);
		localStorage.removeItem("customer_id");
		setUserPopupVisible(false);
	};

	return (
		<form onSubmit={save}>
			<div className="title">
				<Label number={32} />
				<div className="small">
					<div>{user.email}</div>
					<div>ID: {user.id}</div>
				</div>
			</div>
			<div className="row">
				<div className="mb-3 col-sm-6">
					<label className="form-label">
						<Label number={33} />:
					</label>
					<input
						type="text"
						name="name"
						className="form-control"
						defaultValue={user.name}
						required
					/>
				</div>
				<div className="mb-3 col-sm-6">
					<label className="form-label">
						<Label number={34} />:
					</label>
					<input
						type="text"
						name="surname"
						className="form-control"
						defaultValue={user.surname}
						required
					/>
				</div>
			</div>
			<div className="row">
				<div className="mb-3 col-sm-8">
					<label className="form-label">
						<Label number={37} />:
					</label>
					<div className="mobile-container">
						<select name="area_code" defaultValue={user.area_code}>
							{areaCodes.map((el, i) => (
								<option key={i} value={el.dial_code}>
									{el.code + " (" + el.dial_code + ")"}
								</option>
							))}
						</select>
						<input
							type="tel"
							name="mobile"
							className="form-control"
							defaultValue={user.mobile}
							required
						/>
					</div>
				</div>
				<div className="mb-3 col-sm-4">
					<label className="form-label">
						<Label number={92} />:
					</label>
					{user.date_checked == 1 ? (
						<div>
							<Moment format={"DD/MM/YYYY"} locale="it">
								{user.birth_date}
							</Moment>
						</div>
					) : (
						<input
							type="date"
							name="birth_date"
							className="form-control"
							defaultValue={user.birth_date}
							required={settings.customers.birth_date_required == 1}
						/>
					)}
				</div>
				{settings.customers.show_gender == 1 && (
					<div className="mb-3 col-sm-6">
						<label className="form-label">
							<Label number={93} />:
						</label>
						<select
							name="gender"
							className="form-select"
							defaultValue={user.gender}
							required={settings.customers.gender_required == 1}
						>
							<option value=""></option>
							<option value="m">Maschio</option>
							<option value="f">Femmina</option>
						</select>
					</div>
				)}
				{settings.selfordering.multilanguage == 1 && (
					<div className="mb-3 col-sm-6">
						<label className="form-label">
							<Label number={120} />:
						</label>
						<select
							name="customer_lang"
							className="form-select"
							defaultValue={user.customer_lang}
						>
							{settings.global.it == 1 && <option value="it">Italiano</option>}
							{settings.global.en == 1 && <option value="en">English</option>}
							{settings.global.zh == 1 && <option value="zh">Chinese</option>}
						</select>
					</div>
				)}
			</div>
			{legal &&
				Object.keys(legal).map((id, i) => {
					if (id != "privacy" && id != "tos") {
						return (
							<div className="mb-3 form-check" key={i}>
								<input type="hidden" name={id} value="0" />
								<input
									type="checkbox"
									className="form-check-input"
									id={id}
									name={id}
									defaultChecked={user[id] == 1}
									value="1"
								/>
								<label className="form-check-label small" htmlFor={id}>
									{legal[id].label_text}
									<a href="#" onClick={() => setLegalPupop(legal[id])}>
										{legal[id].label_linked}
									</a>
								</label>
							</div>
						);
					}
				})}
			<button
				type="submit"
				className="button mb-3"
				style={{
					backgroundColor: settings.selfordering.color1,
					color: settings.selfordering.color2,
				}}
			>
				<Label number={5} />
			</button>
			<button type="button" className="button" onClick={logout}>
				<Label number={39} />
			</button>
			<hr />
			<button type="button" className="button" onClick={deleteAccount}>
				<Label number={94} />
			</button>
		</form>
	);
}

export default Profile;
