import { useContext, useEffect } from "react";
import { TableContext, UserContext, SettingsContext, ShopContext } from "./App";

function Socket({ socket }) {
	const { settings, load_menu, loadCart, loadSettings } = useContext(SettingsContext);
	const { table, load_table } = useContext(TableContext);
	const { shop } = useContext(ShopContext);
	const { user } = useContext(UserContext);

	useEffect(() => {
		console.log("add socket listener");
		socket.on("connect", onConnect);
		socket.on("disconnect", onDisconnect);
		socket.on("subscribed", onSubrscribed);
		socket.on("events", onSocketEvents);

		onConnect();

		return () => {
			console.log("remove socket listener");
			socket.off("connect", onConnect);
			socket.off("disconnect", onDisconnect);
			socket.off("subscribed", onSubrscribed);
			socket.off("events", onSocketEvents);
		};
	}, [user, table]);

	function onConnect() {
		console.log("Socket connected", socket.id, user.id);
		socket.emit("subscribe", "m" + settings.mandant.id + ".settings.#");
		socket.emit("subscribe", "m" + settings.mandant.id + ".menu.#");
		socket.emit(
			"subscribe",
			"m" + settings.mandant.id + ".s" + shop.id + ".tables.*." + table.id
		);
		socket.emit("subscribe", "m" + settings.mandant.id + ".self_cart.*." + table.id);
	}

	function onDisconnect() {
		console.log("Socket disconnected");
	}

	function onSubrscribed(data) {
		console.log(data);
	}

	function onSocketEvents(message) {
		console.log("new socket event: ", message);
		if (message.table == "tables") {
			if (table) load_table(table.id);
			loadCart();
		}
		if (message.table == "self_cart") loadCart();
		if (message.table == "menu") load_menu();
		if (message.table == "settings") loadSettings();
	}

	return null;
}

export default Socket;
